<template>
  <Fieldset>
    <template #legend>
      <i class="pi pi-discord" style="fontsize: 2rem"></i>
      เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรสุขภาพ)
    </template>
    <form class="card" @submit.prevent="processML" id="validity">
      <div class="p-fluid p-formgrid p-grid">
        <!-- career -->
        <div class="p-field p-col-12 p-md-12">
          <div class="p-formgroup-inline">
            <label><strong>อาชีพที่ต้องการทำในอนาคต</strong></label
            >&nbsp;&nbsp;
            <div
              class="p-field-checkbox"
              v-for="(career, value) in careers"
              :key="value"
            >
              <RadioButton
                :id="value"
                name="career_value"
                :value="value"
                v-model="careerValue"
              />
              <label :for="value">{{ career.text }}</label>
            </div>
          </div>
        </div>

        <!-- sum_tmse -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- sum_sts -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) คะแนนการประเมินความเครียด (ST5)
            <strong>(คะแนนมากคือมีความเครียดมาก)</strong></label
          >
          <br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_sts']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="15"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_sts', true)"
            />
            &nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 - 15 คะแนน)</label>
        </div>

        <div v-if="careerValue == 'career_need_3' || careerValue == 'career_need_4'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) <b>ในระยะเวลา 6 เดือน</b>ที่ผ่านมา ท่านหกล้มกี่ครั้ง
          
            <strong>(ระบุจำนวนครั้งที่หกล้ม)</strong></label
          >
          <br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['fall_times']"
              type="number"
              placeholder="ครั้ง"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="0"
            />
            &nbsp;<label>ครั้ง</label>
          </div>
        </div>

        

        <div v-if="careerValue == 'career_all' || careerValue == 'career_need_1' || careerValue == 'career_need_5'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300510"
            :key="key"
          >
            <RadioButton
              name="health_heavy"
              v-model="criteria['health_heavy']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div v-if="careerValue == 'career_need_1' || careerValue == 'career_need_5'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) สามารถยกของขึ้น-ลง ผลักหรือดึงของ ซ้ำๆบ่อยๆ ได้</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300511"
            :key="key"
          >
            <RadioButton
              name="health_lifting"
              v-model="criteria['health_lifting']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div v-if="careerValue == 'career_need_2'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) สามารถก้มหรือเอี้ยวตัวได้เต็มที่</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300512"
            :key="key"
          >
            <RadioButton
              name="health_bow"
              v-model="criteria['health_bow']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div v-if="careerValue == 'career_need_4'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) สามารถนั่งยองๆ
          หรือมีปัญหาขณะลุกขึ้นยืนจากท่านั่งยอง</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300513"
            :key="key"
          >
            <RadioButton
              name="health_squatting"
              v-model="criteria['health_squatting']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div  v-if="careerValue == 'career_need_3'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) สามารถนั่งบนพื้นได้นาน เช่น นั่งคุกเข่า นั่งขัดสมาธิ
          นั่งพับเพียบหรือมีปัญหาขณะลุกขึ้นยืนจาก ท่านั่งบนพื้น</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300514"
            :key="key"
          >
            <RadioButton
              name="health_kneel"
              v-model="criteria['health_kneel']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div  v-if="careerValue == 'career_need_3'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) มีอาการชา ซู่ซ่าเหมือนเป็นเหน็บ หรือแปลบปลาบคล้ายเข็มตำ
          ที่บริเวณใดบริเวณหนึ่งของแขน ขา มือ หรือ เท้า</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300515"
            :key="key"
          >
            <RadioButton
              name="health_hand"
              v-model="criteria['health_hand']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <div v-if="careerValue == 'career_need_3'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >6) มีปัญหาผิวหนังแพ้ง่าย</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300516"
            :key="key"
          >
            <RadioButton
              name="health_skin"
              v-model="criteria['health_skin']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
        

        <div class="p-formgroup-inline p-col-12 p-sm-12">
          <label for="wight"
            >ในปัจจุบันท่านมีอาการปวดในบริเวณใดของร่างกาย
            และมีระดับความปวดเท่าใดจากระดับ 0-10</label
          >
        </div>
        <DataTable
          :value="dataSet.tablePain"
          class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
        >
          <ColumnGroup type="header">
            <Row>
              <Column header="" :rowspan="2" headerStyle="width:13%;" />
              <Column
                header="ไม่ปวดเลย "
                :colspan="5"
                headerClass="p-text-left"
              />
              <Column
                header="ปวดมากจนทนไม่ได้"
                :colspan="6"
                headerClass="p-text-right"
              />
            </Row>
            <Row>
              <Column header="0" headerClass="p-text-center" />
              <Column header="1" headerClass="p-text-center" />
              <Column header="2" headerClass="p-text-center" />
              <Column header="3" headerClass="p-text-center" />
              <Column header="4" headerClass="p-text-center" />
              <Column header="5" headerClass="p-text-center" />
              <Column header="6" headerClass="p-text-center" />
              <Column header="7" headerClass="p-text-center" />
              <Column header="8" headerClass="p-text-center" />
              <Column header="9" headerClass="p-text-center" />
              <Column header="10" headerClass="p-text-center" />
            </Row>
          </ColumnGroup>

          <Column
            header="Name"
            field="topic"
            bodyStyle="white-space: nowrap; "
          />

          <!-- option 0-->
          <Column header="ไม่ปวดเลย(0)" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="0"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 0-->

          <!-- option 1-->
          <Column header="1" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="1"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 1-->

          <!-- option 2-->
          <Column header="2" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="2"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 2-->

          <!-- option 3-->
          <Column header="3" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="3"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 3-->

          <!-- option  4-->
          <Column header="4" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="4"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 4-->

          <!-- option 5-->
          <Column header="5" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="5"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 5-->

          <!-- option 6-->
          <Column header="6" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="6"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 6-->

          <!-- option 7-->
          <Column header="7" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="7"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 7-->

          <!-- option 8-->
          <Column header="8" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="8"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 8-->

          <!-- option 9-->
          <Column header="9" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="9"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 9-->

          <!-- option 10-->
          <Column header="ปวดมากจนทนไม่ได้(10)" bodyClass="p-text-center">
            <template #body="slotProps">
              <RadioButton
                :name="slotProps.index" :required="true"
                :value="10"
                v-model="slotProps.data['select']"
              />
            </template>
          </Column>
          <!-- /option 10-->
        </DataTable>
      </div>

      <div class="p-d-flex p-jc-center">
        <Button
          type="submit"
          label="วิเคราะห์ผล"
          :icon="['pi pi-spinner', isLoading ? 'pi-spin' : '']"
          iconPos="left"
        />
      </div>
      <Divider align="left" type="solid">
        <b>อธิบายเพิ่มเติม</b>
      </Divider>
      <ul>
        <li>
          เปอร์เซ็นต์ความแม่นยำในการทำนายความสามารถในการทำงาน อยู่ที่
          {{ mapCareerPercentLabel }}
          <br />
          <strong v-if="careerValue != 'career_all'"
            >(
            <span style="color: red"
              >กรณี
              {{ mapCareerLabel }}
              ข้อมูลในการวิเคราะห์เพื่อสร้างแบบจำลองมีจำนวนน้อย
              <br />มีผลต่อความคลาดเคลื่อนในการประเมินความสามารถในการทำงานผู้สุงอายุ</span
            >
            )</strong
          >
        </li>
        <li>ผลวิเคราะห์ความสามารถในการทำงาน มี 3 ระดับ</li>
        <ol >
          <strong v-if="careerValue == 'career_all'">
            <li style="color: red"><strong>มีปัญหาในการทำงาน</strong></li>
            <li style="color: green"><strong>สามารถทำงานได้</strong><br /></li>
            <li style="color: purple">
              <strong>สามารถทำงานได้ดี</strong>
            </li>
          </strong>
          <strong v-if="careerValue != 'career_all'">
            <li style="color: red"><strong>WAI LOW TASK LOW</strong></li>
            <li style="color: orange"><strong>WAI LOW TASK HIGH</strong><br /></li>
            <li style="color: green"><strong>WAI HIGH TASK LOW</strong><br /></li>
            <li style="color: purple"><strong>WAI HIGH TASK HIGH</strong></li>
          </strong>
        </ol>
      </ul>
    </form>
  </Fieldset>
  <Dialog
    :header="'ผลการวิเคราะห์ความสามารถในการทำงาน' + mapCareerLabel"
    v-model:visible="isModal"
    :style="{ width: '50vw' }"
  >
    <p class="p-d-flex p-jc-center" v-html="mapPredictScoreHtml"></p>
    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        @click="() => (isModal = false)"
        class="p-button-text"
      />
    </template>
  </Dialog>
  <ModalSumSts />
  <ModalSumTmse />
</template>

<script>
import axios from "axios";
import { initCustomValidity } from "@/HTMLElement";
import { mixins } from "../Mixins";

import ModalSumSts from "./ModalSumSts.vue";
import ModalSumTmse from "./ModalSumTmse";

export default {
  mixins: [mixins],
  layout: "Private",
  data() {
    return {
      A300510: {
        1: "ทำได้",
        0: "ทำไม่ได้",
      },
      A300511: {
        1: "ทำได้",
        0: "ทำไม่ได้",
      },
      A300512: {
        1: "ทำได้",
        0: "ทำไม่ได้",
      },
      A300513: {
        1: "ทำได้",
        0: "ทำไม่ได้",
      },
      A300514: {
        1: "ทำได้",
        0: "ทำไม่ได้",
      },
      A300515: {
        1: "มีอาการ",
        0: "ไม่มีอาการ",
      },
      A300516: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      careerValue: "career_all",
      criteria: this.getCareerCriteria("career_all"),
      careers: {
        career_all: { text: "ไม่ระบุ", percent: "78" },
        career_need_1: { text: "ค้าขายอาหาร", percent: "76" },
        career_need_2: { text: "ทำไร่/ทำสวน", percent: "77" },
        career_need_3: { text: "กรีดยาง", percent: "90" },
        career_need_4: { text: "ทำสวนผลไม้", percent: "100" },
        career_need_5: { text: "ทำนา", percent: "90" },
        //career_need_6: 'ไม่เลือก 7 อาชีพ'
      },
    };
  },
  watch: {
    careerValue: {
      handler(value) {
        this.criteria = this.getCareerCriteria(value);
        this.$store.commit('entryTrain/updateTablePain', value);
      },
      immediate: true,
    },
  },
  computed: {
    mapPredictScoreHtml() {
      const { text = "-", color } = this.grade[this.score] || {
        color: "black",
      };
      return `<h1 style="color:${color};font-size:50px;font-weight: bold;"> ${text}</h1>`;
    },
    mapCareerLabel() {
      if (this.careerValue == "career_all") {
        return ": ";
      } else {
        const { text } = this.careers[this.careerValue];
        return ` อาชีพ: ${text}`;
      }
    },
    mapCareerPercentLabel() {
      const { percent } = this.careers[this.careerValue];
      console.log("percent ::==", percent);
      return `${percent}%`;
    },
    dataSet() {
      return this.$store.state.entryTrain.entity;
    },
  },
  mounted() {
    this.$nextTick(() => {
      console.log("initCustomValidity validate thai message !");
      initCustomValidity("validity");
    });
  },
  methods: {
    openModalHelper(modalName, isOpen) {
      //console.log('modalName ::==', modalName)
      this.$store.commit("prediction/setModal", { modalName, isOpen });
    },
    async processML() {
      const dataTableData = this.dataSet.tablePain;
      const painCriteria = {};
      dataTableData.forEach((row) => {
        painCriteria[row.name] = row.select; // Assuming 'select' holds the selected value
      });

      // Merging pain criteria with existing criteria
      this.criteria = { ...this.criteria, ...painCriteria };

      const { VUE_APP_API_ENDPOINT } = process.env;
      let payload = {
        labels: this.criteria,
        loop: 10,
        area_id: 999,
        career_value: this.careerValue,
        route: "predict2",
      };
      this.message = null;
      this.isLoading = true;

      //payload['diagnosis_drug_count'] = null

      //alert('payload ::==' + JSON.stringify(payload))
      try {
        const { data: result } = await axios({
          url: `${VUE_APP_API_ENDPOINT}/predict/train2`, // labels=weight,bmi,waistline&area_id=999&algo=2
          data: payload,
          method: "POST",
        });
        const { status, data } = result;
        if (status.code == "01") {
          alert(
            "เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ"
          );
        } else {
          var dataJson = JSON.parse(data);
          var score = dataJson.datas.score;
          this.message = JSON.stringify({
            ...data,
            grade: this.grade[score] || "ดีมาก",
          });
          this.score = score;
          this.isModal = true;
        }
      } catch (error) {
        console.log("error ::==", error);
        alert(
          "เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ"
        );
      }
      this.isLoading = false;
    },
    getCareerCriteria(careerValue) {
      if (careerValue == "career_all") {
        return {
          sum_tmse: null,
          sum_sts: null,
          health_heavy: null,
          pain_neck: null,
          pain_shoulder: null,
          pain_scapula: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
          pain_foot: null,
        };
      } else if (careerValue == "career_need_1") {
        return {
          sum_tmse: null,
          sum_sts: null,
          health_heavy: null,
          health_lifting: null,
          pain_neck: null,
          pain_shoulder: null,
          pain_scapula: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
        };
      } else if (careerValue == "career_need_2") {
        return {
          sum_tmse: null,
          sum_sts: null,
          health_bow: null,
          pain_neck: null,
          pain_shoulder: null,
          pain_scapula: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
          pain_foot: null,
        };
      } else if (careerValue == "career_need_3") {
        return {
          sum_tmse: null,
          sum_sts: null,
          health_kneel: null,
          health_skin: null,
          health_hand: null,
          fall_times: null,
          pain_shoulder: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
        };
      } else if (careerValue == "career_need_4") {
        return {
          sum_tmse: null,
          sum_sts: null,
          fall_times: null,
          health_squatting: null,
          pain_neck: null,
          pain_shoulder: null,
          pain_hand: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
        };
      } else if (careerValue == "career_need_5") {
        return {
          sum_tmse: null,
          sum_sts: null,
          health_heavy: null,
          health_lifting: null,
          pain_neck: null,
          pain_shoulder: null,
          pain_scapula: null,
          pain_back: null,
          pain_hip: null,
          pain_knee: null,
        };
      }
    },
  },
  components: {
    ModalSumSts,
    ModalSumTmse,
  },
};
</script>

<style lang="scss" scoped>
</style>